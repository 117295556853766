import { Routes, Route, Navigate, BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import { SearchPage } from './components/pages/SearchPage'



function App() {
  

  return (
    <Router>
      <Routes>
        <Route path="/:chain" element={<SearchPage/>} >
          <Route path=":tokenAddress" element={<SearchPage/>} />
        </Route>
        {/*<Route path="/eth" element={<SearchPage chain={"ETH"}/>} >
          <Route path=":tokenAddress" element={<SearchPage chain={"ETH"}/>} />
        </Route>
        <Route path="/avax" element={<SearchPage chain={"AVAX"} />} >
          <Route path=":tokenAddress" element={<SearchPage chain={"AVAX"}/>} />
        </Route>
        <Route path="/cro" element={<SearchPage chain={"CRO"} />} >
          <Route path=":tokenAddress" element={<SearchPage chain={"CRO"}/>} />
        </Route>
        <Route path="/ftm" element={<SearchPage chain={"FTM"} />} >
          <Route path=":tokenAddress" element={<SearchPage chain={"FTM"}/>} />
        </Route>*/}
        <Route exact path="/"element={<Navigate replace to="/bsc" />} /> 
          {/*
          <Route path="/import" exact element={<ImportPage/>} />
          <Route path="/" exact element={<Profile profile={profile}/>} />
          */}
      </Routes>  
    </Router>
    

  );
}

export default App;

// onChange={e => setState({val: e.target.value})}