import React, {useState, useEffect, useRef} from 'react'
import './SearchPage.css'
import { NavLink, Navigate, useNavigate, useParams, useLocation, matchPath } from 'react-router-dom'
import { Link, Switch, Loading, NextUIProvider, createTheme, Row, Tooltip, Card, Grid, Container, Input, Text, Spacer, Button} from '@nextui-org/react';
import Web3 from 'web3'
import {BigNumber} from "bignumber.js"

export const SearchPage = (props) => {

  // console.log('start')
  let navigate = useNavigate()
  let urlParams = useParams()
  const location = useLocation();
  /*const matchTokenPath = matchPath(location.pathname, {
    path: `/:chain/:token`,
  });*/
  const [loadingbtn, setloadingbtn] = useState(false)
  const [errorbtn, seterrorbtn] = useState('primary')
  const [selectedChain, setSelectedChain] = useState(urlParams.chain.toUpperCase() ? urlParams.chain.toUpperCase() : '')
  const [autoCheck, setAutoCheck] = useState(false)
  const [isChecking, setIsChecking] = useState(false) // new
  const [address, updateAddress] = useState(urlParams.tokenAddress ? urlParams.tokenAddress : '') // new
  const [isInvalid, setIsInvalid] = useState(false)
  const tokenTextBoxRef = useRef() // new
  const [typedAdress, setTypedAddress] = useState()
  const searchBtnRef = useRef() // new
  const errorMsgRef = useRef() // new

  useEffect( () => {  // Quando il component viene renderizzato
    console.log("location 1", location)
      if (address.match(/^0x[a-fA-F0-9]{40}$/) != null) { // se il token corrisponde al pattern  0x39h39hf3...
        tokenTextBoxRef.current.value = address // inserisco la string del token nella textbox di ricerca
        tokenTextBoxRef.current.focus() // imposto il focus sulla textbox per renderla attiva
        setTypedAddress(tokenTextBoxRef.current.value)
        searchToken(address)  // poi lancio la ricerca
      }
    }, []
  )

  useEffect( () => {              // quando cambia la url
      if (address.match(/^0x[a-fA-F0-9]{40}$/) != null) {
        setIsChecking(true)       // facio partire il loading
        searchToken(address)      // poi lancio la ricerca
      }
    }, [window.location.pathname]
  )
  
  useEffect(() => { //quando cambia la selectedChain
    if (address.match(/^0x[a-fA-F0-9]{40}$/) != null) {
      navigate('/'+ selectedChain.toLowerCase() + '/' + address) // se il token corrisponde al pattern 0xj6158be... vado alla nuova url
    } else {
      setIsInvalid(true) // se no metto il messagio di errore
      //navigate('/'+ selectedChain.toLowerCase())  //se no resto sulla url della chain senza token
    }
  }, [selectedChain])

  useEffect(() => { // quando cambia il valore della variabile del token
    if (address.match(/^0x[a-fA-F0-9]{40}$/) != null) {
      setIsInvalid(false) // rimuovo il messaggio di errore
      navigate('/'+ selectedChain.toLowerCase() + '/' + address) // se il token corrisponde al pattern 0xj6158be... vado alla nuova url
      
    } else if (window.location.pathname ) {
      setIsInvalid(true) // metto il messagio di errore
    }
     
  }, [address])

  useEffect(() =>{ // quando cambia il testo nella textbox
    console.log("typedAddress: ", typedAdress)
    setIsInvalid(false) // tologo il messaggio di errore
  }, [typedAdress])

  // Di seguito alcuni useEffect usati solo per console.log

  useEffect(() =>{
      console.log("isChecking: ", isChecking)
    }, [isChecking])

  // useEffect(() =>{
  //   console.log("tokenTextBOxRef: ", tokenTextBoxRef)
  //   setTypedAddress(tokenTextBoxRef.current.value)
  // }, [tokenTextBoxRef])

  useEffect(() =>{
    console.log("isInvalid: ", isInvalid)
  }, [isInvalid])
  

  const darkTheme = createTheme({
      type: 'dark',
  })

//   var address = ''

  // function updateAddress(inputAddress) {const address = inputAddress; console.log(address);};
//   const updateAddress = (inputAddress) => { address = inputAddress;}
  
  console.log("address: ", address)

  const [results, setResults] = useState("")
  
  var autoIsEnabled = false;
  
//   console.log(autoIsEnabled)

//   const [autoIsEnabled, setAutoIsEnabled] = useState(false);
  const autoUpdateSwitch = () => {
    //   setAutoIsEnabled(previousState => !previousState)

    if (autoCheck === true){
     setAutoCheck(false)}
    else{
      setAutoCheck(true)};

        // all(address);
    }

  if (autoCheck === true){
    setTimeout(console.log(autoIsEnabled), 5000);
    // console.log('IS ENABLED?')
    

    };

  // if (autoIsEnabled === false){autoIsEnabled = true}
  // else if (autoIsEnabled === true){autoIsEnabled = false}
  // console.log('enable before')
  // console.log(autoIsEnabled)
     

  // while (autoIsEnabled === true)  {
  //   autoUpdateSwitch()
  // };

  let routerAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E"  
  var rpc = "https://bsc-dataseed2.binance.org"
  let checkerContract = "0xd45757a3565b7215082d3ef75fad0b7c805ee8e3"
  let fromAddress = "0x8894e0a0c962cb723c1976a4421c95949be2d4e3"
      /**
        from: string,
        honeyCheckAddress: string,
        token: string,
        router: string,
        rcpAddress: string
       */

  // function changeChain(selectedChain){
    if (selectedChain === "BSC"){
      console.log( `Selected ${selectedChain}`)
      routerAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E"  
      rpc = "https://bsc-dataseed2.binance.org"
      checkerContract = "0xd45757a3565b7215082d3ef75fad0b7c805ee8e3"
      fromAddress = "0x8894e0a0c962cb723c1976a4421c95949be2d4e3"
      // chainbutton = "green"
      }
    else if (selectedChain === "BSCTest"){
      console.log( `Selected ${selectedChain}`)
      routerAddress = "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3"  
      rpc = "https://data-seed-prebsc-1-s1.binance.org:8545/"
      checkerContract = "0xb6D7D0fFC5f62E90Df782F1E4c3A06637406ee1C"
      fromAddress = "0x3bd70e10d71c6e882e3c1809d26a310d793646eb"
      // chainbutton = "green"
      } 
    else if (selectedChain === "AVAX"){
      //<Navigate replace to="/avax" />
      //navigate("/avax")
      console.log( `Selected ${selectedChain}`)
      routerAddress = "0x60aE616a2155Ee3d9A68541Ba4544862310933d4"  
      rpc = "https://rpc.ankr.com/avalanche"
      checkerContract = "0x799f6B908b0DFa778Ab69F6d7448589Dc7cFF7D0"
      fromAddress = "0x765ccb180f15ead17bbffc38de4478d26214312b"
      // console.log( `new ${rpc}`)

      }

    else if (selectedChain === "CRO"){
      console.log( `Selected ${selectedChain}`)
      routerAddress = "0x145677fc4d9b8f19b5d56d1820c48e0443049a30"  
      rpc = "https://evm.cronos.org"
      checkerContract = "0x799f6B908b0DFa778Ab69F6d7448589Dc7cFF7D0"
      fromAddress = "0x573fbc5996bfb18b3f9b9f8e96b774905bcdc8b6"
      }
    else if (selectedChain === "FTM"){
      console.log( `Selected ${selectedChain}`)
      routerAddress = "0xf491e7b69e4244ad4002bc14e878a34207e38c29"  
      rpc = "https://rpc.ftm.tools/"
      checkerContract = "0x799f6B908b0DFa778Ab69F6d7448589Dc7cFF7D0"
      fromAddress = "0x431e81E5dfB5A24541b5Ff8762bDEF3f32F96354"
      }    
      else if (selectedChain === "ETH"){
        console.log( `Selected ${selectedChain}`)
        routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"  
        rpc = "https://mainnet.infura.io/v3/ea1ddced6e684e52bbbc7f62f04d399c"
        checkerContract = "0x799f6B908b0DFa778Ab69F6d7448589Dc7cFF7D0"
        fromAddress = "0xF977814e90dA44bFA03b6295A0616a897441aceC"
        }        
        else if (selectedChain === "DOGE"){
          console.log( `Selected ${selectedChain}`)
          routerAddress = "0xa4EE06Ce40cb7e8c04E127c1F7D3dFB7F7039C81"  
          rpc = "https://rpc01-sg.dogechain.dog"
          checkerContract = "0x03844a7a21c83a9e69807f2adec6d58c70f22105"
          fromAddress = "0xDe0D9e7f99f15356BFA72b6433426E90eA524F9a"
          }    
  // }
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')



  const searchToken = async (address) => {
    console.log("dentro searchToken1")
    //navigate(`/${address}`)
    if (address.match(/^0x[a-fA-F0-9]{40}$/) == null) {
      console.log('Address not valid')
      
        setTimeout( 
            seterrorbtn('primary')
          , 600);
          setIsChecking(false)
        return;
        };
        
    
    // let tokenName = '';
    // let tokenSymbol = '';
      
    console.log(routerAddress)
    console.log(rpc)
    console.log(checkerContract)
    console.log(fromAddress)

    setloadingbtn(true)

    async function updateTokenInformation(web3, tokenAddress) {
      web3.eth.call({
          to: tokenAddress,
          value: 0,
          gas: 150000,
          data: encodeBasicFunction(web3, 'name'),
      })
      .then(value => {
        console.log('VALUE: ', tokenName)
        
        setTokenName(web3.eth.abi.decodeParameter('string', value));
        
        console.log('TokenName: ', tokenName)
      })
      .catch(err => {console.log(err)});

      web3.eth.call({
          to: tokenAddress,
          value: 0,
          gas: 150000,
          data: encodeBasicFunction(web3, 'symbol'),
      })
      .then(value => {
          console.log(value);
          setTokenSymbol(web3.eth.abi.decodeParameter('string', value));

      })
      .catch(err => {console.log(err)});
      }

    function encodeBasicFunction(web3, funcName) {
      return web3.eth.abi.encodeFunctionCall({
          name: funcName,
          type: 'function',
          inputs: []
      }, []);
    }
  

    const contractAbi = [
        {
          inputs: [],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "targetTokenAddress",
              type: "address",
            },
            {
              internalType: "address",
              name: "idexRouterAddres",
              type: "address",
            },
          ],
          name: "honeyCheck",
          outputs: [
            {
              components: [
                {
                  internalType: "uint256",
                  name: "buyResult",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "tokenBalance2",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "sellResult",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "buyCost",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "sellCost",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "expectedAmount",
                  type: "uint256",
                },
              ],
              internalType: "struct honeyCheckerV5.HoneyResponse",
              name: "response",
              type: "tuple",
            },
          ],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "router",
          outputs: [
            {
              internalType: "contract IDEXRouter",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];
      
      const TEST_AMOUNT = 1 * 10 ** 16; // Equal with 2 wETH, 2 wBNB, 2 wCRO...
      const GAS_LIMIT = "4500000"; // 4.5 million Gas should be enough
      
      const RunHoneyContract = async (from,to, token, router, rcpAddress
      ) => {
        let buyTax = 0;
        let sellTax = 0;
        let buyGasCost = 0;
        let sellGasCost = 0;
        let isHoneypot = 0;
        let expectedAmountOut = 0;
        const web3 = new Web3(rcpAddress);
        setloadingbtn(true)

        const gasPrice = await web3.eth.getGasPrice();
        // const block = await web3.eth.getBlockNumber()
        const honeyCheck = new web3.eth.Contract(contractAbi);
      
        const data = honeyCheck.methods.honeyCheck(token, router).encodeABI();
        updateTokenInformation(web3, token);
        let honeyTxResult = 11;
        // console.log('Block ',block)
        // console.log('Block ',block-1)
        try {
          honeyTxResult = await web3.eth.call({
            // this could be provider.addresses[0] if it exists
            from,
            // target address, this could be a smart contract address
            to,
            // optional if you want to specify the gas limit
            gas: GAS_LIMIT,
            gasPrice: Math.floor(Number(gasPrice) * 1.2).toString(),
            // optional if you are invoking say a payable function
            value: TEST_AMOUNT,
            // nonce
            nonce: undefined,       
            // this encodes the ABI of the method and the arguements
            data,
          });
        } catch (error) {
          return {
            buyTax: -1,
            sellTax: -1,
            isHoneypot: 1,
            error: error,
          };
        }
      
        const decoded = web3.eth.abi.decodeParameter(
          "tuple(uint256,uint256,uint256,uint256,uint256,uint256,uint256)",
          honeyTxResult
        );
      
        buyGasCost = decoded[3];
        sellGasCost = decoded[4];
      
        const res = {
          buyResult: decoded[0],
          remaining: decoded[1],
          sellResult: decoded[2],
          expectedAmount: decoded[5],
          expectedAmountOut: decoded[6],
        };
        console.log(res.expectedAmountOut);
      
        buyTax =
          (1 -
            new BigNumber(res.buyResult)
              .dividedBy(new BigNumber(res.expectedAmount))
              .toNumber()) *
          100;
        buyTax = buyTax.toFixed()

        console.log(buyTax);
        console.log(res.sellResult);
        console.log(res.buyResult);
        sellTax =
            (1 -
              new BigNumber(res.sellResult)
                .dividedBy(new BigNumber(res.expectedAmountOut))
                .toNumber()) *
              100
        sellTax = sellTax.toFixed()
      
        return {
          buyTax,
          sellTax,
          buyGasCost,
          sellGasCost,
          isHoneypot,
        };
      };
      


      function txprice(x){
        if (selectedChain === "ETH"){          
          let bnbprice = 2000
          return x*25/(10**9)*bnbprice}
        else if (selectedChain === "DOGE"){          
          let bnbprice = 0.2
          return x*25/(10**9)*bnbprice}
        else{        
          let bnbprice = 300
          return x*5/(10**9)*bnbprice}

    };
    
      await RunHoneyContract(
        fromAddress,
        checkerContract,
        address,
        routerAddress,
        rpc
      )
        // .catch()
      .then((e) => {
      
        console.log(e);
        let buy_tax = e.buyTax
        let sell_tax = e.sellTax
        let buyGasUsed = e.buyGasCost
        let sellGasUsed = e.sellGasCost
        let isHoneypot = e.isHoneypot
        let maxtrans = 0

        let txfeebuy = txprice(buyGasUsed).toFixed(2)
        let txfeesell = txprice(sellGasUsed).toFixed(2)



        if (isHoneypot === 1){
          setIsChecking(false)
          setResults(        
            <>
                <Card color='error' justify="center">
                  <Row justify="center" align="center">
                    <Text h4 size='1.7rem' color="white" weight="bold">SCAM</Text>
                  </Row>
                  <Spacer y={0.1} />
                  <Row justify="center" align="center">
                    <Text h4 size='1.2rem' color="white" weight="bold">You won't be able to sell</Text>
                  </Row>
                  <Spacer y={0.3} />
                  <Row justify="center">
                  {/* <Text size='1.3rem' color="black" h3 weight="bold">{tokenName}</Text> */}
                  </Row>
                  <Spacer y={0.2} />
    
                  <Row justify="center" align="center">
                  <Text h6 color="white">Address:</Text>
                  </Row>
                  <Row justify="center" align="center">
                  <Text h6 size='0.8rem' color="white">{address}</Text>
                  </Row>
                  <Spacer y={0.3} />

                  <Row justify="center" align="center">
                  <Text h6 size='0.6rem' align="center"  color="white">If the token has yet to launch ignore this result and try again after launch.</Text>
                  </Row>

                </Card>
              </>)
        }
        else{






        let cardtitle = "It's Safu"
        // TAX 70%+ SCAM ALERT
        let cardcolor = "success"
        if (buy_tax > 60 || sell_tax > 60) {
            cardcolor = "error"
            cardtitle = "EXTREME TAX"
        }

        // BUY TAX FEE COLOR PICKER
        let buytaxcolor = ''
        if (buy_tax <= 10) {
            buytaxcolor = "white"
        }
        else if (buy_tax > 10 && buy_tax < 25) {
            buytaxcolor = "yellow"
        }
        else if (25 <= buy_tax) {
            buytaxcolor = "red"
        }
        if (cardcolor === "error"){
            buytaxcolor = "yellow"
        }

        // SELL TAX FEE COLOR PICKER
        let selltaxcolor = ''
        if (sell_tax <= 10) {
            selltaxcolor = "white"
        }
        else if (sell_tax > 10 && sell_tax < 25) {
            selltaxcolor = "yellow"
        }
        else if (25 <= sell_tax) {
            selltaxcolor = "red"
        }
        if (cardcolor === "error"){
            selltaxcolor = "yellow"
        }

    //     console.log(tokenName, tokenSymbol,maxtrans, buy_tax, sell_tax, addressToOutput);
        setResults(        
        <>
            <Card color={cardcolor} justify="center">
              <Row justify="center" align="center">
                <Text h4 size='1.3rem' color="white" weight="bold">{cardtitle}</Text>
              </Row>
              <Spacer y={0.3} />
              <Row justify="center">
              <Text size='1.7rem' color="black" h3 weight="bold">{tokenName}</Text>
              </Row>
              <Spacer y={0.2} />

              {/* <Row justify="center" align="center" >
              {maxtrans}
              </Row> */}
              <Spacer y={0.3} />

              <Row justify="center" align="center">
                
              <Text h5 size='1.3rem' color={buytaxcolor}>Buy TAX: {buy_tax}%</Text>
              
              
              </Row>
              <Row justify="center" align="center">
              <Text h5 size='1.3rem' color={selltaxcolor}>Sell TAX: {sell_tax}%</Text>
              </Row>
              <Spacer y={0.4} />

              <Row justify="center" align="center">
              <Text h6 size='1.1rem' color="white">Buy Fee: {txfeebuy}$</Text>
              </Row>
              <Row justify="center" align="center">
              <Text h6 size='1.1rem' color="white">Sell Fee: {txfeesell}$</Text>
              </Row>
              <Spacer y={0.3} />

              <Row justify="center" align="center">
              <Text h6 color="white">Address:</Text>
              </Row>
              <Row justify="center" align="center">
              <Text h6 size='0.8rem' color="white">{address}</Text>
              </Row>


            </Card>
          </>)
          setIsChecking(false)
      }
    })
      .catch(err => {
        // console.log(addressToOutput, tokenName ,tokenSymbol);

        console.log("catch 2 controllare")


    }

    );
    setloadingbtn(false)
    } 
  
console.log("pathname before return div: ", window.location.pathname)


  return (
    <div id="searchpage-div">
    {/*selectedChain === 'BSC' && window.location.pathname !== '/bsc' ? <Navigate to='/bsc' /> :
    selectedChain === 'AVAX' && window.location.pathname !== '/avax' ? <Navigate to='/avax' /> :
    selectedChain === 'FTM' && window.location.pathname !== '/ftm' ? <Navigate to='/ftm' /> :
  selectedChain === 'CRO' && window.location.pathname !== '/cro' ? <Navigate to='/cro' /> : null*/}
    <NextUIProvider theme={darkTheme}>
      <div className="searchpage-div">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          {/* <Text h1 size="3rem"  css={{textGradient: '70deg, $yellow500 -20%, $red500 100%'}} weight="bold">SafuCheck.com</Text>    
          <Spacer y={1} /> */}

          {/* <Row justify="center" align="center">    */}
          <Grid.Container gap={0.5} justify="center" align="center" >
          <Grid>
          {/* <Button.Group> */}
          
            <Button onClick={() => {updateAddress(tokenTextBoxRef.current.value); setSelectedChain('BSC')}} color={((selectedChain ==='BSC') && "success") || ((selectedChain !=='BSC') && "primary")} auto>BSC</Button>
            </Grid>
            <Grid>
            <Button onClick={() => {updateAddress(tokenTextBoxRef.current.value); setSelectedChain("ETH")}} color={((selectedChain ==='ETH') && "success") || ((selectedChain !=='ETH') && "primary")}auto>ETHv2</Button>
            </Grid>
            <Grid>

            {/*<NavLink className="navlink" to="/avax" style={({ isActive }) => {
                return {
                  display: "block",
                  margin: "1rem 0",
                  color: isActive ? "success" : "primary",
                };
              }}>AVAX</NavLink>*/}
            </Grid>
          </Grid.Container>

          {/* </Button.Group> */}

          
          {/* <Spacer y={1} /> */}
          {/* <Container  css ={{ mw: "430px" }}>

          </Container> */}
          <Grid.Container gap={2} justify="center">
          <Grid xs={10} sm={5}>
              {/* <MockItem text="1 of 3" /> */}
              {results}

              </Grid>
          </Grid.Container>

          <Spacer y={1.5} />


          {/* <Container xl alignContent="center" justify="center" css={{ mw: "430px" }}> */}
          <Grid.Container  justify="center" >
          <Grid xs={9}>
          <Row justify="center" align="center">   
          
          <Input id="address-text-input" ref={tokenTextBoxRef} onKeyPress={(e) => {if(e.key === 'Enter'){updateAddress(tokenTextBoxRef.current.value); }} } onChange={(e) => {setTypedAddress(e.target.value); updateAddress(e.target.value)} }  labelPlaceholder="0x Address" placeholder={address && address !== "" ? address : "0x Address"} size='lg' color = 'white'/>
          </Row>
          </Grid>
          </Grid.Container>
          {/* </Container> */}
          {/*isInvalid ? <Text p color="red" ref={errorMsgRef}>Type a valid token address</Text> : null*/}

          {/*isInvalid ? <Text p color="red" ref={errorMsgRef}>Type a valid token address</Text> : null*/}

          <Spacer y={1.5} />
         

          <Row justify="center" align="center">   

          <Button ref={searchBtnRef} onClick={() => {updateAddress(tokenTextBoxRef.current.value); searchToken(address)} } color={isInvalid ? "error" : "primary"} /*disabled={loadingbtn}*/>
          {isChecking ? <Loading color="white" size="sm"/> : isInvalid ? "Invalid Address" : "Check"}
          </Button>
          <Spacer y={0.5} />
          
         
          {/* <Switch onChange={autoUpdateSwitch()} /> */}
          </Row>

          <Spacer y={5} />

          <Grid>
            <Tooltip content="Beware that this tool can be tricked and results could change anytime, always check the contract source code and do your own research. This is a free tool and we hold no responsabilities." contentColor="warning">
              <Button flat auto color="warning">
                Is this safe?
              </Button>
            </Tooltip>
          </Grid>
          <Spacer y={1} />

          {/* <Row justify="center" align="center">    */}
          {/* <Button flat auto href="https://github.com/" color="success">
                Join Degen Calls Channel
              </Button> */}
              {/* <Link block color="success" href="https://t.me/SafuDevCalls">
              Join our Degen Calls Channel
              </Link>   
          </Row> */}

      </div>
    </NextUIProvider></div>
  )
}